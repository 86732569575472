.video-embed-description {
  font: 300 50px/55px "Montserrat", sans-serif;

  color: #292929;
  text-transform: uppercase;
  margin: 0 0 45px;
  padding-top: 20px;
}
#center_column{
  &>.container{
    width: auto;
    &>.row{
      margin:0;
    }
  }
}

.product-information .tab-content{
  .product-video-tab-content{
    padding-left:20%;
    padding-right:20%;
  }
  @media (max-width: 768px){
    .product-video-tab-content{
      padding-left:0;
      padding-right:0;
    }
  }
}

#admin-menu, #admin-menu .dropdown{
  z-index: 9999;
}

.grippie{
  display:none !important;
}

.page-heading {
    font: 600 40px/1em "Montserrat", sans-serif !important;
    color: #292929 !important;
    margin: 0 0 30px !important;
    text-align: left !important;
    overflow: hidden;
}
.ajax-progress{
  font-size:13px;
}

#node-27{
  margin-bottom: 40px;
}
ul.step li{
  width: 20%;

  &.last span::after{
    display: none;
  }
}

@media (max-width: 767px){
  ul.step li {
      width: 100%;
      border-left-width: 1px;
      border-left-color: #d9d9d9;
  }
}

.delete-wrapper{
  margin:0 20px;
  width: 18px;
  line-height: 28px;
  display:inline-block;
  position: relative;
  font-size: 23px;
  color: #1e2024;

  &::before{
    content: "\f014";
  }

  &:hover{
    color: silver;
  }

  input{
    @include stretched;
    border:0;
    background:0;
  }
}

.line-item-summary{
  .line-item-total{
    &-label{
      font: 600 40px/1em "Montserrat",sans-serif;
      color: #1e2024;
      text-transform: uppercase;
    }
    &-raw{
      margin-left: 10px;
      font: bold 40px/1em "Montserrat",sans-serif;
      color: #275988;
      font-size: 32px;
      line-height: 1em;
    }
  }
}
.top_menu li.first a{
  color: #d92928;
}
.top_menu ul li a.active {
    color: #275988
}
.breadcrumb br{
  display: none;
}

#payment-details{
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 20px;
}
.element-invisible{
  display:none;
}
#user-login, #user-profile-form{
  margin-bottom: 30px;
}

.tabs{
  margin-bottom: 30px;
  li {
    display: inline-block;
    margin-right: 20px;
  }
}

.form-actions .btn{
  margin-right:20px;
}

#block-system-main{
  margin-bottom: 30px;
}
/* .logo-copyright{
  text-align: center;
  img{
    display: inline;
  }
  li{
    float: none;
    display: inline-block;
  }
} */
.user-data{
  font-weight: bold;
}
.views-table{
  th{
    white-space: nowrap;
    img{
      display: inline-block;
      padding-left:5px;
    }
  }
  .views-field-nothing{
    width:1px;
  }
}

.order-status{
  margin-bottom:20px
}
ul.product_list .product-image-container .product_img_link{
  padding: 0 !important;
}
ul.product_list.grid .ajax-block-product .product-container .old-price{
  position: absolute;
}
#thumbs_list ul#thumbs_list_frame, #thumbs_list li{
    height: auto;
}
#cart_summary p{
    margin-bottom: 0;
}
.product_list{
    li:nth-child(3n+1){
        clear:left;
    }
    @media (max-width: 992px) {
        li:nth-child(3n+1){
            clear:none;
        }
        li:nth-child(2n+1){
            clear:left;
        }
    }
}
.commerce-order-handler-area-order-total .commerce-price-formatted-components{
    width: auto;
    .component-title{
        font: 600 20px/1em "Montserrat",sans-serif;
        color: #1e2024;
        text-transform: uppercase;
    }
    .component-total{
        margin-left: 10px;
        font: bold 40px/1em "Montserrat",sans-serif;
        color: #275988;
        font-size: 32px;
        line-height: 1em;
    }
}
.field-name-field-gallery .field-items{
    display: flex;
    flex-wrap: wrap;
}
.demonstration{
    margin: 0 auto 20px auto;
    max-width: 520px;
    overflow-x:auto;
    iframe {
        width: 520px;
        height: 350px;
    }
}
.product-quick-view{
    min-height: 600px;
}

#confirmation-dialog{
  position:fixed;
  z-index:100;
  background:rgba(0,0,0,0.8);
  bottom:0;
  left:0;
  right:0;
  padding:10px 20px;
  color:#fff;
  font-size:13px;
  line-height:18px;}
  #confirmation-dialog a{color:#fff;
    white-space: nowrap;
    text-decoration:underline}
    #confirmation-dialog p{padding-right:100px}
    #confirmation-dialog button{border-radius:3px;
    padding: 0 10px;
  height:30px;
  line-height:30px;
  background:#fff;
  float: right;
  color:#000;
  margin: 9px 20px 0 20px;
  border:0}
.header-login-content .btn-md {
  padding: 18px 5px;
}
.product-calculator-tab-content {
  .vat{
    font-size: 14px;
    color: rgb(53, 57, 64);
  }
  .total_with_vat{
    font-size: 18px;
    line-height: 44px;
  }
}
.logo-copyright {
  margin-bottom: 10px;
  img {
    display: inline-block;
    height: 35px;
  }
}
.page-user-register, .page-user-login, .page-user-password {
  .breadcrumb .item-list, .wrapper.container .btn-primary {
    display: none;
  }
}
.views-field.views-field-edit-delete {
  width: 1px;
}