@media (max-width: 991px){
  .product_list {
    .views-row-odd{
      clear:left;
    }
  }
}

@media (max-width: 600px){
  .quantity_wanted_p label{
    display:none;
  }
}