#commerce-helper-cart-full-form {
  .form-submit{
    margin-top: 30px;
  }

  .quantity_wanted_p{
    display:none;
    margin:0 20px 10px 0;
  }
  .product_info{
  }
  .product{

    border-bottom: 1px solid #eee;
    margin-bottom: 14px;
    padding-bottom: 8px;
    line-height: 37px;

    &:last-child{
      border-bottom:0;
    }
  
    
    .price{
      color:#275988;
      font-size: 24px;
      font-weight: normal;
      float:left;
      margin-right:10px;
      margin-bottom: 10px;
      
    }

    .quick-view{
      display: block;
      float: left;
      text-decoration: underline;
      margin-bottom: 10px;
      margin-right: 10px;

    }
    input[type=checkbox]+label{
      font-size: 18px;
      line-height: 24px;
      text-transform: none;
      margin-bottom: 10px;
    }

  }

  h5{
    font: 300 22px/28px "Montserrat", sans-serif;
    padding-top: 30px;
    margin-bottom: 30px;
  }

  hr{
    border-top:2px solid #292929;
  }
}
.layer_cart_products_list_multiple, 
.layer_cart_products_list{
  background:#fff;
  padding-bottom:30px;
}
.layer_cart_product_title{
  margin-top: 20px;
}
.layer_cart_products_list_multiple{
  max-height: 350px;
  overflow: auto;
  .item{
    margin-bottom:30px;
    .product-image-container{
      width: 30%;
      float:left;
      padding:0 20px;
    }
    .layer_cart_product_title{
      margin-top: 0;
      padding:0 20px 0 0;
      width: 70%;
      margin-left:30%;
      font-size:14px;
      line-height: 18px;
    }
  }
}
#product-calculator-tab-content{
  padding-top:40px;
}