.commerce-checkout-form-checkout{
  @include clearfix;
  margin:0 -15px;
  margin-bottom: 30px;
  legend{
    font: 600 20px/24px "Montserrat",sans-serif;
    color: #292929;
    text-transform: uppercase;
    margin: 20px 0;
    border:0;
  }
  .block{
    margin-bottom: 0;
  }

  .selector{ 
        border: 2px solid #bebebe;
  }

  .view-commerce-cart-summary{
    margin-top: 20px;
  }
  .commerce-order-handler-area-order-total .commerce-price-formatted-components{
    width: auto;;
  }
  .field-name-commerce-order-total {
    .field-label{
      font-weight: normal;
    }
    .field-item{ 
      font: bold 30px/32px "Montserrat",sans-serif !important;
      text-align:left;
      color: #292929;
      white-space: nowrap;
      margin-left:0 !important;
    }
  }

  .commerce-order-handler-area-order-total{
    .component-title, .component-total{
        display: block;
    }
  }
}

#edit-commerce-payment-payment-method-commerce-codrules-cash + label{
  display: none;
}


.commerce_payment{
  margin-bottom: 30px;
  .ajax-progress {
    display: block;
  }
  label{
    line-height: 35px !important;
  }
  img{
    display: inline;
  }
  .commerce-paypal-icon{
    top:0;
  }
  .label{
    margin-right: 10px;
  }
  legend {
    display: none;
  }
}

.button-operator{
  display:inline-block;
  padding: 0 20px;
}

.commerce-order-handler-area-order-total{
    margin-bottom: 20px;
    .component-title{
      font-weight: normal;
      
    }
    .component-total{
        display:block;
      font: bold 20px/32px "Montserrat",sans-serif !important;
      text-align:left;
      color: #292929;
      white-space: nowrap;
      margin-left:0 !important;
    }
}

.component-type-commerce-price-formatted-amount{
    .component-total{
        font: bold 30px/32px "Montserrat",sans-serif !important;
    }
}

.checkout-completion-message{
  min-height:240px;
  margin-bottom: 30px;
}
.commerce-line-item-views-form{
  .form-actions{
    display:none;
  }
}
.commerce-checkout-form-checkout, #edit-account{
  .form-item{
    margin-bottom: 20px;
    .form-required{
      color: #f13340;
    }
    label{
      display:block;
      color: #292929;
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 8px;
      font-weight: 500;
    }

    .description{
      margin-top: 5px;
      font-size:15px;
    }
  }

  .form-text, .form-textarea{
    display: block;
    padding: 19px 12px 19px 20px;
    height: 58px;
    background: #fff;
    border: 2px solid #bebebe;
    font-size: 15px;
    line-height: 20px;
    box-sizing: border-box;
    color: #353940;
    transition: all .3s ease;
    box-shadow: none;

    &:focus{
      box-shadow: 0 0 10px rgba(41,41,41,.5);
      border-color: #fff;
    }
  }
  .form-textarea{
    height: auto;
    min-height: 180px;
  }
}
#uniform-edit-commerce-payment-payment-details-credit-card-type{
  width: 300px !important;

}
#edit-commerce-payment-payment-details-credit-card-number{
  width: 280px !important;
  font-size: 22px !important;
}
.commerce-credit-card-expiration{
  float:left;
  margin-right:30px;
  .selector {
    float:left;
    width: 86px !important;
  }
  .commerce-month-year-divider{
    float:left;
    line-height:56px;
    padding: 0 15px;
  }
  .form-item-commerce-payment-payment-details-credit-card-code{
    float:left;
  }
}
div.selector span{
  text-align: left;
    width: auto !important;
}
.back-to-cart{
  font-size:18px;
  text-transform: none;
  margin-top: 20px;
  display: inline-block;
  padding-left:0;
  &:hover{
    color:#275988;
  }
}

.commerce-checkout-form-checkout > div > .messages{
  margin-left:15px;
  margin-right:15px;
}
fieldset.cart_contents {
    .commerce-price-formatted-components{
        margin-left: 0;
        td {
            padding-left: 0;
        }
    }
}
.commerce-paypal-icons{
    display: none;
}

.commerce_coupon {
  button.form-submit {
    margin-bottom: 20px;
    border: 2px solid #275988;
    color: #275988;
    text-transform: uppercase;
    cursor:pointer;
    transition: all 0.3s;
    font: 600 13px/15px "Montserrat",sans-serif;
    padding: 13px 15px;
    background: none;
    &:hover {
      color: #fff;
      background: #275988;
    }
  }
}

#block-commerce-checkout-progress-indication li{
  position: relative;
  &:nth-child(1){z-index: 5;}
  &:nth-child(2){z-index: 4;}
  &:nth-child(3){z-index: 3;}
  &:nth-child(4){z-index: 2;}
  &:nth-child(5){z-index: 1;}
}